import React, { Component } from 'react';
import '../css/Global.css';
import '../css/Footer.css';
import { Event } from "../Tracking";

export default class FooterComponent extends Component {
  render() {
    return (
      <div className="container footer">
        <p>
          <a
            className="link"
            href="https://www.linkedin.com/in/carolinaaokeli/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={()=> 
              Event("LINKS", "LinkedIn", "LINKEDIN")
          }
          >
            LinkedIn
          </a>
          <a
            className="link footer-link"
            href="https://www.instagram.com/caro.linarts/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={()=> 
              Event("LINKS", "Instagram", "IG")
          }
          >
            Instagram
          </a>
          <a
            className="link footer-link"
            href="https://medium.com/@carolina.aoke.li"
            target="_blank"
            rel="noopener noreferrer"
            onClick={()=> 
              Event("LINKS", "Medium", "MEDIUM")
          }
          >
            Medium
          </a>
          <a
            className="link footer-link"
            href="https://github.com/CarolinaLi/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={()=> 
              Event("LINKS", "GitHub", "GITHUB")
          }
          >
            Github
          </a>
        </p>
      </div>
    );
  }
}
